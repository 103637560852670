import React, { FC, useState } from "react";

export interface PriceTagCardProps {
  className?: string;
  sharingRate?: string;
  coupleRate?: string;
  currency_symbol?:string
  children?: React.ReactNode;
}

/**Regular and Clubmember Rate **/
const PriceTagRnCRate: FC<PriceTagCardProps> = ({
  className = "",
  children,
  sharingRate,
  coupleRate,
  currency_symbol,
}) => {
  return (
    <div className="flex  flex-col ">
      <div className="flex flex-col">
        <div className="">
          <ul>
            <li
              className={className}
              style={{ boxShadow: "2px 2px 8px 0 rgba(0,0,0,0.1)" }}
            >
              <a href="" className="flex  items-left space-x-1">
                <div className=" w-1/5 ml-4 flex-shrink-0">
                  <div className="w-10 h-10 flex items-center justify-center ">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24px" height="24px" viewBox="0 0 390.000000 398.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,398.000000) scale(0.100000,-0.100000)"
fill="#03844e" stroke="none">
<path d="M1645 3906 c-245 -56 -422 -219 -515 -474 -36 -98 -60 -229 -60 -322
0 -61 -2 -69 -25 -84 -51 -33 -56 -139 -14 -317 22 -94 51 -136 87 -125 16 6
24 -6 55 -81 129 -311 335 -516 556 -553 274 -46 553 183 711 582 19 50 25 57
44 52 47 -11 79 59 106 238 17 102 7 164 -31 199 -25 24 -29 34 -29 82 0 288
-139 572 -346 707 -109 71 -213 102 -359 106 -78 2 -141 -1 -180 -10z"/>
<path d="M1150 2031 c-224 -80 -419 -179 -625 -317 -272 -181 -376 -298 -448
-501 -28 -79 -32 -103 -35 -225 -5 -159 5 -189 85 -278 136 -151 600 -368 998
-466 257 -63 388 -78 675 -78 287 0 418 15 675 78 401 99 862 315 1000 469 77
85 87 118 83 277 -3 120 -7 145 -34 222 -60 168 -156 290 -324 414 -256 188
-494 316 -764 410 l-100 35 -55 -54 c-155 -149 -305 -217 -481 -217 -176 0
-352 82 -495 229 -21 23 -42 41 -45 40 -3 0 -52 -17 -110 -38z"/>

</g>
</svg>
                  </div>
                </div>
                <div className=" w-4/5 text-left flex-grow">
                  <span className="block font-bold pb-3 text-xl text-red-500">
                    Sharing Sharing:
                  </span>
                  <span className="block text-lg text-gray-900">
                    Rate:{" "}
                    <strong className="text-xl pt-4">{currency_symbol}{sharingRate}</strong>
                  </span>
                </div>
              </a>
            </li>
            <li
              className={className}
              style={{ boxShadow: "2px 2px 8px 0 rgba(0,0,0,0.1)" }}
            >
              <a href="" className="flex items-left space-x-1">
                <div className="w-1/5 ml-4 flex-shrink-0">
                  <div className="w-10 h-10 flex items-center justify-center ">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="354px" height="345px" viewBox="0 0 812.000000 484.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,484.000000) scale(0.100000,-0.100000)"
fill="#03844e" stroke="none">
<path d="M1960 4624 c-378 -81 -620 -402 -662 -876 -10 -114 -14 -130 -33
-142 -36 -23 -48 -91 -43 -224 6 -124 27 -203 70 -257 17 -22 27 -26 52 -22
31 5 32 4 56 -61 29 -84 104 -230 161 -313 62 -93 193 -224 275 -275 118 -74
170 -89 304 -89 127 0 178 15 297 86 164 99 343 341 438 593 22 58 23 60 61
60 36 1 40 4 66 56 74 145 78 390 7 442 -26 20 -29 27 -29 82 0 85 -24 235
-55 339 -93 319 -327 546 -622 603 -93 18 -257 17 -343 -2z"/>
<path d="M5550 4625 c-384 -82 -638 -423 -666 -895 -7 -100 -8 -106 -36 -130
-24 -21 -31 -37 -40 -97 -25 -160 39 -402 107 -405 11 0 26 1 33 3 9 3 22 -19
37 -58 76 -200 214 -409 341 -518 319 -272 669 -207 955 178 59 78 168 287
190 364 11 39 13 41 44 36 28 -4 36 -1 57 28 53 71 85 241 67 364 -10 75 -21
100 -48 114 -15 8 -19 28 -25 108 -41 571 -365 925 -846 922 -58 0 -134 -7
-170 -14z"/>
<path d="M1420 2471 c-286 -94 -607 -266 -902 -483 -192 -142 -295 -276 -367
-481 -30 -86 -31 -94 -31 -263 0 -203 3 -213 96 -313 71 -77 178 -148 360
-239 1008 -505 1996 -523 3009 -56 77 36 144 69 149 74 6 6 -13 33 -51 73 -71
74 -121 149 -154 232 -21 54 -23 75 -23 210 1 128 4 164 27 246 45 171 114
311 209 430 26 32 48 61 48 63 0 11 -367 248 -472 304 -146 79 -332 160 -464
202 l-101 32 -69 -66 c-339 -325 -749 -327 -1090 -3 -39 37 -75 67 -80 67 -5
-1 -47 -13 -94 -29z"/>
<path d="M4965 2456 c-297 -105 -620 -284 -885 -487 -174 -133 -287 -289 -348
-479 -50 -156 -59 -325 -23 -420 52 -139 210 -265 511 -408 412 -195 785 -305
1191 -348 207 -22 555 -15 754 16 447 68 923 236 1299 458 98 59 208 159 247
226 l29 51 0 176 c0 150 -3 185 -21 238 -75 229 -196 384 -429 548 -292 206
-625 377 -878 453 l-74 21 -71 -68 c-204 -196 -444 -277 -676 -228 -146 30
-302 119 -416 234 -33 33 -67 60 -75 60 -8 0 -69 -19 -135 -43z"/>
</g>
</svg>

                  </div>
                </div>
                <div className="w-4/5 text-left flex-grow">
                  <span className="block font-bold pb-3 text-xl text-red-500">
                    Couple:
                  </span>
                  <span className="block text-lg text-gray-900 ">
                    Rate:{" "}
                    <strong className="text-xl pt-9 ">{currency_symbol}{coupleRate}</strong>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

interface Price {
  single_sharing: string;
  couple: string;
}

interface PriceCategory {
  regular: Price;
  clubMember: Price;
}

interface Tab {
  title: string;
  prices: {
    type: string;
    single: string;
    couple: string;
  }[];
}

interface Data {
  title: string;
  tabs: Tab[];
}

function formatPrices(prices: PriceCategory[]): Data {
  const titles: string[] = [
    "⬇️ 3 STAR ⭐⭐⭐",
    "⬇️ 4 STAR ⭐⭐⭐⭐",
    "⬇️ 5 STAR ⭐⭐⭐⭐⭐",
  ];

  return {
    title: "Pricing",
    tabs: prices.map((price, index) => ({
      title: titles[index],
      prices: [
        {
          type: "Regular",
          single: price.regular.single_sharing,
          couple: price.regular.couple,
        },
        {
          type: "Club Member",
          single: price.clubMember.single_sharing,
          couple: price.clubMember.couple,
        },
      ],
    })),
  };
}

/**Pricing table */
interface PricingTableProps {
  prices: [
    {
      regular: {
        single_sharing: "";
        couple: "";
      };
      clubMember: {
        single_sharing: "";
        couple: "";
      };
    }
  ];
}

const PricingTable: React.FC<PricingTableProps> = ({ prices }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const data: Data = formatPrices(prices);

  return (
    <div className="bg-white p-6 shadow-sm rounded-lg">
      <div className="mx-auto">
        <h2 className="text-xl font-bold text-gray-800 mb-4 ">{data.title}</h2>

        <div className="flex flex-col">
          {/* Tabs */}
          <div className="grid gap-1 grid-cols-3">
            {data.tabs.map((tab, index) => (
              <button
                key={index}
                className={`p-4 text-left font-normal text-sm sm:text-base border-b-2  border-gray-300 ${
                  activeTab === index
                    ? "bg-gray-100 text-gray-800 border-gray-500"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>

          {/* Tab Content */}
          <div className="">
            {data.tabs.map((tab, index) => (
              <div
                key={index}
                className={`transition-all duration-300 ${
                  activeTab === index ? "block" : "hidden"
                }`}
              >
                <table className="w-full bg-gray-100 border-collapse">
                  <thead>
                    <tr className="">
                      <th className="p-2 border-b font-semibold text-sm sm:text-base text-left">
                        Type
                      </th>
                      <th className="p-2 border-b text-sm sm:text-base text-left">
                        Single Sharing
                      </th>
                      <th className="p-2 border-b text-sm sm:text-base text-left">
                        Couple
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tab.prices.map((price, priceIndex) => (
                      <tr key={priceIndex}>
                        <td className="p-2 border-b font-semibold text-sm sm:text-base text-left">
                          {price.type}
                        </td>
                        <td className="p-2 border-b  text-red-600 text-left">
                          {price.single}
                        </td>
                        <td className="p-2 border-b  text-red-600 text-left">
                          {price.couple}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/**Regular Rate */
export interface PriceTagRRate {
  className?: string;
  children?: React.ReactNode;
}

const DateTag: FC<PriceTagRRate> = ({ className = "", children }) => {
  return (
    <div
      className="flex flex-col bg-white rounded-lg shadow-md"
      style={{ paddingTop: "110px", paddingBottom: "110px" }}
    >
      <div className="flex flex-col">
        <div>
          <h2 className="text-2xl font-bold text-center">
            <div style={{ color: "#03844e", lineHeight: "1.5" }}>
              24th - 30th August
            </div>
          </h2>
        </div>
      </div>
    </div>
  );
};

export { PriceTagRnCRate, PricingTable };

import { useState, useEffect } from "react";
import axios from "axios";

const useSaveTourLeads = (formValues, emailData) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = "https://workstation.adansitravels.com/api_save_tour_leads";
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const isFormValuesEmpty =
        !formValues || Object.keys(formValues).length === 0;

      if (isFormValuesEmpty) {
        setError(
          "Form values are empty. Please provide the required information."
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      try {
        //console.log(formValues , "====")
        const response = await axios.post(
          BASE_URL,
          { data: formValues },
          {
            headers: {
              "adway-key": API_KEY,
            },
          }
        );
        //console.log(response);
        setData(response.data);
        setSubmitted(true);
        sendLeadEmail(emailData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [formValues, API_KEY]);

  return { data, isLoading, submitted, error };
};

const sendLeadEmail = async (emailData) => {
  const BASE_URL = "https://workstation.adansitravels.com/api_sendEmail";
  const API_KEY = process.env.REACT_APP_API_KEY;

  try {
    const response = await axios.post(
      BASE_URL,
      { data: emailData },
      {
        headers: {
          "adway-key": API_KEY,
        },
      }
    );
    console.log("Email sent successfully:", response);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

export { useSaveTourLeads, sendLeadEmail };

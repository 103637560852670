import React, { FC, Fragment, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTour  } from '../../../redux/slices/globalConfigReducer';
import { getCurrencySymbol, formatAmount, hasAdultOrCouple, formatTripName, tour_params , getTotalAmount, sortKeysAlphabetically } from "hooks/Global/index"; 
import {
  useFetchHomeActiveTourByName
} from "../../../hooks/tours";
import SectionSliderNewCategories from "../../../components/Adways/RelatedTourBox";
import BackgroundSection from  "../../../components/BackgroundSection/BackgroundSection";


const StayDetailPageContainer: FC<{}> = () => {
  //
  // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const cart = useSelector((state) => state.cart.tour_cart); // Access state
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const booking_base_url = useSelector((state) => state.globalConfig.bookingEngineUrl); // Access state
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const toursData = useSelector((state) => state.globalConfig.selected_tour); // Access state
  const rates = sortKeysAlphabetically(toursData?.rates) || {}; // Ensure rates is initialized as an empty object if undefined

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const { id } = useParams(); // Fetching the ID from URL params
  const navigate = useNavigate();


  const goToCheckout = () => {
    const { destination, name } = toursData;
    const baseURL = "https://workstation.adansitravels.com/online_bookings/";
    const userId = false;
    const booking_url = userId ? `${baseURL}?t=${name}&d=${destination}&u=${userId}` : `${baseURL}/?t=${name}&d=${destination}`;
  //  openModalAmenitiesWaitingBookingEngine();
  
   // setTimeout(() => {
     // setIsOpenModalBookingEngine(false);
      //console.log("redirected")
      //navigate(booking_url);
      window.open(booking_url, '_blank'); 
    //}, 10000); // Wait for 30 seconds (30000 milliseconds)
  };

  // function closeModalAmenities() {
  //   setIsOpenModalAmenities(false);
  // }

  // function openModalAmenities() {
  //   setIsOpenModalAmenities(true);
  // }

  const handleOpenModalImageGallery = () => {
   /// router(`${id}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  // const renderSection1 = () => {
  //   return (
  //     <div className="listingSection__wrap !space-y-6">
  //       {/* 1 */}
  //       <div className="flex justify-between items-center">
  //         <Badge name="Wooden house" />
  //         <LikeSaveBtns />
  //       </div>

  //       {/* 2 */}
  //       <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
  //         Beach House in Collingwood
  //       </h2>

  //       {/* 3 */}
  //       <div className="flex items-center space-x-4">
  //         <StartRating />
  //         <span>·</span>
  //         <span>
  //           <i className="las la-map-marker-alt"></i>
  //           <span className="ml-1"> Tokyo, Jappan</span>
  //         </span>
  //       </div>

  //       {/* 4 */}
  //       <div className="flex items-center">
  //         <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
  //         <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
  //           Hosted by{" "}
  //           <span className="text-neutral-900 dark:text-neutral-200 font-medium">
  //             Kevin Francis
  //           </span>
  //         </span>
  //       </div>

  //       {/* 5 */}
  //       <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

  //       {/* 6 */}
  //       <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
  //         <div className="flex items-center space-x-3 ">
  //           <i className=" las la-user text-2xl "></i>
  //           <span className="">
  //             6 <span className="hidden sm:inline-block">guests</span>
  //           </span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <i className=" las la-bed text-2xl"></i>
  //           <span className=" ">
  //             6 <span className="hidden sm:inline-block">beds</span>
  //           </span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <i className=" las la-bath text-2xl"></i>
  //           <span className=" ">
  //             3 <span className="hidden sm:inline-block">baths</span>
  //           </span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <i className=" las la-door-open text-2xl"></i>
  //           <span className=" ">
  //             2 <span className="hidden sm:inline-block">bedrooms</span>
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={toursData?.days+" Day(s)"} />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
         { formatTripName(toursData?.name)}
        </h2>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-map-marker-alt text-2xl "></i>
            <span className="">
            <span className=" sm:inline-block">{ toursData?.destination }</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-umbrella-beach text-2xl"></i>
            <span className=" ">
            <span className=" sm:inline-block">{ toursData?.tag }</span>
            </span>
          </div>
        
       
        </div>
      </div>
    );
  };


  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Package Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div
        className="text-neutral-6000 dark:text-neutral-300"
        dangerouslySetInnerHTML={{ __html: toursData?.overview }}
      ></div>
      </div>
    );
  };


  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Inclusions and Exclusions </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` What's in store for you with this package?`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
    

<div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
  {toursData?.tripInclusions?.filter((_, i) => i < 12).map((item, index) => (
    <div key={index} className="flex items-center space-x-3">
      {/* Conditionally render different icons based on the type */}
      {item.type === "Inclusions" ? (
        <i className="text-3xl las la-check-square" style={{ color : "green" }}></i>
      ) : (
        <i className="text-3xl las la-times-circle" style={{ color : "red" }}></i>
      )}
      <span>{item.item}</span>
    </div>
  ))}
</div>


        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        {/* <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div> */}
        {/* {renderMotalAmenities()} */}
      </div>
    );
  };


  // const renderMotalAmenities = () => {
  //   return (
  //     <Transition appear show={isOpenModalAmenities} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="fixed inset-0 z-50 overflow-y-auto"
  //         onClose={closeModalAmenities}
  //       >
  //         <div className="min-h-screen px-4 text-center">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0"
  //             enterTo="opacity-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100"
  //             leaveTo="opacity-0"
  //           >
  //             <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
  //           </Transition.Child>

  //           {/* This element is to trick the browser into centering the modal contents. */}
  //           <span
  //             className="inline-block h-screen align-middle"
  //             aria-hidden="true"
  //           >
  //             &#8203;
  //           </span>
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 scale-95"
  //             enterTo="opacity-100 scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 scale-100"
  //             leaveTo="opacity-0 scale-95"
  //           >
  //             <div className="inline-block py-8 h-screen w-full max-w-4xl">
  //               <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
  //                 <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
  //                   <h3
  //                     className="text-lg font-medium leading-6 text-gray-900"
  //                     id="headlessui-dialog-title-70"
  //                   >
  //                     Amenities
  //                   </h3>
  //                   <span className="absolute left-3 top-3">
  //                     <ButtonClose onClick={closeModalAmenities} />
  //                   </span>
  //                 </div>
  //                 <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
  //                   {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
  //                     <div
  //                       key={item.name}
  //                       className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
  //                     >
  //                       <i
  //                         className={`text-4xl text-neutral-6000 las ${item.icon}`}
  //                       ></i>
  //                       <span>{item.name}</span>
  //                     </div>
  //                   ))}
  //                 </div>
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </Dialog>
  //     </Transition>
  //   );
  // };

  // const renderSection4 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Room Rates </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="flow-root">
  //         <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Friday - Sunday</span>
  //             <span>$219</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Rent by month</span>
  //             <span>-8.34 %</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Minimum number of nights</span>
  //             <span>1 night</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Max number of nights</span>
  //             <span>90 nights</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection5 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Host Information</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* host */}
  //       <div className="flex items-center space-x-4">
  //         <Avatar
  //           hasChecked
  //           hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
  //           sizeClass="h-14 w-14"
  //           radius="rounded-full"
  //         />
  //         <div>
  //           <a className="block text-xl font-medium" href="##">
  //             Kevin Francis
  //           </a>
  //           <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
  //             <StartRating />
  //             <span className="mx-2">·</span>
  //             <span> 12 places</span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className="block text-neutral-6000 dark:text-neutral-300">
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //             />
  //           </svg>
  //           <span>Joined in March 2016</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
  //             />
  //           </svg>
  //           <span>Response rate - 100%</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
  //             />
  //           </svg>

  //           <span>Fast response - within a few hours</span>
  //         </div>
  //       </div>

  //       {/* == */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       <div>
  //         <ButtonSecondary href="/author">See host profile</ButtonSecondary>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection6 = () => {
  //   if(toursData?.tour_itinerary?.length > 0 ){
  //   return (
  //     <div className="listingSection__wrap">
  //     <div>
  //       <h2 className="text-2xl font-semibold">Tour Itinerary</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-10 mb-10"></div>
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         {toursData && toursData?.tour_itinerary.map((item, index) => (
  //       <CommentListing
  //         key={index}
  //         name={item.name}
  //         description={item.description}
  //         image={item.image+index}
  //       />
  //     ))}
  //   </div></div>
  //     </div>
  //   );
  // }else{
  //   return null
  // }
  // };

  // const renderSection7 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Location</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           San Diego, CA, United States of America (SAN-San Diego Intl.)
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* MAP */}
  //       <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
  //         <div className="rounded-xl overflow-hidden z-0">
  //           <iframe
  //             title="x"
  //             width="100%"
  //             height="100%"
  //             loading="lazy"
  //             allowFullScreen
  //             referrerPolicy="no-referrer-when-downgrade"
  //             src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
  //           ></iframe>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection8 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Things to know</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Cancellation policy</h4>
  //         <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
  //           Refund 50% of the booking value when customers cancel the room
  //           within 48 hours after successful booking and 14 days before the
  //           check-in time. <br />
  //           Then, cancel the room 14 days before the check-in time, get a 50%
  //           refund of the total amount paid (minus the service fee).
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Check-in time</h4>
  //         <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
  //           <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
  //             <span>Check-in</span>
  //             <span>08:00 am - 12:00 am</span>
  //           </div>
  //           <div className="flex space-x-10 justify-between p-3">
  //             <span>Check-out</span>
  //             <span>02:00 pm - 04:00 pm</span>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className="text-lg font-semibold">Special Note</h4>
  //         <div className="prose sm:prose">
  //           <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
  //             <li>
  //               Ban and I will work together to keep the landscape and
  //               environment green and clean by not littering, not using
  //               stimulants and respecting people around.
  //             </li>
  //             <li>Do not sing karaoke past 11:30</li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSidebar = () => {
  //   return (
  //     <div className="listingSectionSidebar__wrap shadow-xl">
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold">
  //           $119
  //           <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
  //             /night
  //           </span>
  //         </span>
  //         <StartRating />
  //       </div>

  //       {/* FORM */}
  //       <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //         <StayDatesRangeInput className="flex-1 z-[11]" />
  //         <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <GuestsInput className="flex-1" />
  //       </form>

  //       {/* SUM */}
  //       <div className="flex flex-col space-y-4">
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>$119 x 3 night</span>
  //           <span>$357</span>
  //         </div>
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>Service charge</span>
  //           <span>$0</span>
  //         </div>
  //         <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <div className="flex justify-between font-semibold">
  //           <span>Total</span>
  //           <span>$199</span>
  //         </div>
  //       </div>

  //       {/* SUBMIT */}
  //       <ButtonPrimary href={"/checkout"}>Reserve</ButtonPrimary>
  //     </div>
  //   );
  // };


  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-sm z-20">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {getCurrencySymbol(toursData?.startsFromCurrency)}{formatAmount(toursData?.startsFrom)}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /Starting From
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

       
        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-2xl">
          <StayDatesRangeInput className="flex-1 z-[11] px-5 py-2" toursData={toursData} />
        </form>  

  

<div className=" z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
    <div className="overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
      <div className="relative flex flex-col px-5 py-6 space-y-5">
      {Object.entries(rates).map(([key, value]) => (
      <div key={key} className="flex justify-between">
        <span>

        <div className={`nc-NcInputNumber flex items-center justify-between space-x-5`}
      data-nc-id="NcInputNumber">
       <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
        {key.charAt(0).toUpperCase() + key.slice(1)}
        </span>
      </div>
      <div
        className={`nc-NcInputNumber flex items-center justify-between w-28`}>
        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
        {getCurrencySymbol(toursData.startsFromCurrency)}{formatAmount(value)}
            </span>
      </div>
    </div>

          <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-3"></div>
        </span>
        
      </div>
    ))}
    
      </div>



 
    
    </div>
  </div>


        {/* <ButtonPrimary  className="" onClick={ goToCheckout }>Book Now</ButtonPrimary> */}

 {!toursData?.soldout &&      
<ButtonPrimary className="hidden sm:inline-block" onClick={goToCheckout}>
  Book Now
</ButtonPrimary>
}
        {/* SUBMIT */}
      
      </div>
    );
  };


  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={toursData.images[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {toursData.images.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 3 ? "hidden sm:block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {/* {renderSection4()} */}
          {/* <SectionDateRange /> */}
          {/* {renderSection5()}
          {renderSection6()}
          {renderSection7()}
          {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>


      <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Related Tours"
            subHeading="Explore some related tours we think you may like!"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            tourCategory={toursData?.destination}
            selectedTourName={toursData?.name }
          />
        </div>
      </div>


    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}

import React, { FC, useEffect } from "react";
import SectionSubscribe2 from "../../../components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "../../../shared/SocialsList/SocialsList";
import Label from "../../../components/Label/Label";
import Input from "../../../shared/Input/Input";
import Textarea from "../../../shared/Textarea/Textarea";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import office1 from "../../../images/head-office.jpg";
export interface PageContactProps {}

const branches = [
  {
    name: "Head Office",
    location:
      "136 La Bawaleshi Road. American House, East Legon, Accra - Ghana",
    map: "https://maps.google.com/?cid=17419632851567509528&entry=gps",
  },
  {
    name: "The Adansi Travel House",
    location: "U2003 La Bawaleshie Road, East Legon, Accra - Ghana",
    map: "https://maps.app.goo.gl/AuwQLqEE1TXJkPu99",
  },
  {
    name: "Ashaley Botwe",
    location: "Plaza Building, Quartey Papafio Ave, Accra - Ghana",
    map: "https://maps.app.goo.gl/1J8Qxb9oQjjcTiqN8",
  },
  {
    name: "Takoradi",
    location: " Takoradi mall, Gate 2, Takoradi - Ghana",
    map: "https://maps.app.goo.gl/oUyneZDmEPCZeF4z9",
  },
  {
    name: "Kumasi",
    location: "Opposite Kumasi Polytechnic, Kumasi - Ghana",
    map: "https://maps.app.goo.gl/ghU5WErbAMaZGZ7o6",
  },
  {
    name: "Ikeja, Lagos",
    location: "78/79 Trinity Mall, Awolowo way, Ikeja Lagos - Nigeria",
    map: "https://maps.app.goo.gl/z7pcPi2cJnGCpRMC6",
  },
];

const contactInfo = [
  {
    title: "Head Office",
    desc: [
      "Visit our head office located at 136, La Bawaleshie Road, East Legon American House, AccraGhana. Our team will be happy to assist you with any inquiries.",
    ],
  },
  {
    title: "EMAIL",
    desc: ["info@adansitravels.com", "sales@adansitravels.com"],
  },
  {
    title: "PHONE",
    desc: ["+233 302548674", "+233 243606648"],
  },
];

const PageContact: FC<PageContactProps> = ({}) => {

  return (
    <div className={`nc-PageContact overflow-hidden `}>
      {/* CONTACT US */}
      <div className="mb-16 lg:mb-16 pb-10 px-0 sm:p-4 xl:p-8 ">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>

        <div className="container flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-2 ">
          <div className="pr-4 space-y-8">
            {contactInfo.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>

                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {Array.isArray(item.desc) ? (
                    item.desc.map((descItem, descIndex) => (
                      <div key={descIndex}>{descItem}</div>
                    ))
                  ) : (
                    <div>{item.desc}</div>
                  )}
                </span>
              </div>
            ))}
               <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            <div><a target="_blank" href="https://wa.me/233531011617?text=Welcome%20to%20Adansi%20Travels%20WhatsApp">Click to WhatsApp us : <strong>233 531 011 617</strong></a></div>
            </span>
            <div>

         

    
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                SOCIALS
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>

          <div className="flex justify-center items-center w-full h-full">
            <div className="relative w-full h-screen md:w-[550px] md:h-[600px] border-2 border-[#03844e] overflow-hidden">
              <img
                src={office1}
                alt="our story"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* OFFICE BRANCHES SECTION */}
      <div className="mx-16 mb-8">
        <h2 className="text-xl font-semibold text-neutral-600 md:text-xl xl:text-2xl dark:text-neutral-100 ">
          Our Office Branches
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 xl:grid-cols-4 lg:grid-cols-3 md:mx-1">
          {branches.map((branch, index) => (
            <div
              className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900 rounded-3xl hover:shadow-md transition-shadow shadow-sm shadow-green-500`}
              data-nc-id="CardAuthorBox2"
              key={index}
            >
              <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                <span className="absolute top-2 mt-8 w-12 h-12 text-2xl rounded-full mb-4">
                  <i className="las la-building text-4xl sm:text-3xl text-green-700"></i>
                </span>

                <div className="mt-20">
                  <h2 className={`text-base font-medium`}>
                    <span className="line-clamp-1">{branch.name}</span>
                  </h2>
                  <span
                    className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400 text-left`}
                  >
                    {branch.location}
                  </span>

                  <span
                    className={`block mt-2 text-sm text-neutral-700 dark:text-neutral-400 text-center `}
                  >
                    <i className="las la-map text-md sm:text-md text-green-700"></i>
                    <a href={branch.map} target="_blank" rel="noopener noreferrer">
                      Google
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageContact;


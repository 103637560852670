import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchAllTrendingTours = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //console.log("isLoading:", isLoading, "data:", data);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_trending_tours';
  const API_KEY = process.env.REACT_APP_API_KEY;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(BASE_URL, {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
};


const useFetchHomeActiveToursByDestination = ( destinations ) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_tours_by_destination';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(BASE_URL, 
          { destinations }, 
          {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
}



const useFetchHomeActiveTourByName = (name) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetchSelectedActiveTourByName';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      // Check if name is empty
      if (!name) {
        setIsLoading(false);
        setData([]); // Ensure data is empty if name is not provided
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.post(
          BASE_URL,
          { name },
          {
            headers: {
              'adway-key': API_KEY
            }
          }
        );
        setData(response.data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]); // Ensure data is empty if name is not provided
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [name], ); // Include name in the dependency array to re-fetch data when name changes

  return { data, isLoading };
};


const useFetchRelatedTours = (category , name  ) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetchSelectedRelatedTours';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(BASE_URL, 
          { name , category  }, 
          {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
};


const useFetchTourDestinations = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetchAllTourDestinations';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(BASE_URL, 
          {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
};

const useFetchAllTours = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetchAllTour';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(BASE_URL, 
          {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
};



const checkIfUserAlreadySubmited=()=>{
  const resp = localStorage.getItem("fdbk");
  return resp;
  }


const useFetchAllToursByDestination = ( destinations ) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_all_tours_by_destination';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(BASE_URL, 
          { destinations }, 
          {
          headers: {
            'adway-key': API_KEY
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
}


const useSubmitCustomereedbackForm = (formValues) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = 'https://workstation.adansitravels.com/website_submit_customer_feedback'; // Update with your actual base URL
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const isFormValuesEmpty = !formValues || Object.keys(formValues).length === 0;

    if (isFormValuesEmpty) {
      setError('Form values are empty. Please provide the required information.');
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          BASE_URL,
          {data : formValues},
          {
            headers: {
              'adway-key': API_KEY
            }
          }
        );
        if(response.data.status){
          localStorage.setItem("fdbk" , true )
        }
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error submitting the form. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [formValues]);

  return { data, isLoading, error };
};



const useFetchTourDataWithName = (tourname) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Initialize as false since no fetch initially
  const BASE_URL = 'https://workstation.adansitravels.com/website_fetch_selected_tourdata_by_name_4_feedback';
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      if (!tourname) return; // Exit if no tourname is provided
      setIsLoading(true);
      try {
        const response = await axios.post(
          BASE_URL,
          { tourname : tourname },
          {
            headers: {
              'adway-key': API_KEY,
            },
          }
        );
        console.log(response,"==", tourname)
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tourname]); // Depend on tourname, so it triggers only when tourname changes

  return { data, isLoading };
};



export { 
  useFetchAllTrendingTours , 
  useFetchHomeActiveToursByDestination , 
  useFetchHomeActiveTourByName , 
  useFetchRelatedTours,
  useFetchTourDestinations,
  useFetchAllTours,
  useFetchAllToursByDestination,
  useSubmitCustomereedbackForm,
  useFetchTourDataWithName
};






import React, { FC } from "react";
import {PriceTagRnCRate} from "../componetSrc/PriceComponent";
import {DateTag} from "../componetSrc/DateComponent";
import {formatAmount} from "hooks/Global/index";

export interface PriceDateSectionProps {
  className?: string;
  children?: React.ReactNode;
  startDate?: string;
  endDate?: string;
  duration?: string;
  currency_symbol?: string;
  rate?: {
    childWithBed: 0;
    couple: 0;
    childWithoutBed: 0;
    singleSupplement: 0;
    adult: 0;
    infant: 0;
  };
}

const PriceDateSection: FC<PriceDateSectionProps> = ({
  className = "",
  children,
  currency_symbol,
  startDate,
  endDate,
  duration,
  rate,
}) => {
  return (
    <div className="container mx-auto bg-gray-100 p-4">
      <h4 className=" text-2xl sm:text-3xl text-green-700 font-bold pt-4">
        Package Pricing
      </h4>
      <div className="text-black-800 pt-2" style={{ fontSize: "18px" }}>
        Find the Perfect date and Fit for Your Budget and Ideal Vacation
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 sm:p-8">
        <div className="px-2 pt-8 sm:p-4">
          {/* Content for the first column */}
          <DateTag startDate={startDate} endDate={endDate} duration={duration}/>
        </div>
        <div className="p-0 sm:p-4">
          {/* Content for the second column */}
          <PriceTagRnCRate className="flex flex-col bg-white pt-4 pb-8 my-2 rounded-lg "
            sharingRate={formatAmount(rate?.adult || 0)}
            coupleRate={formatAmount(rate?.couple || 0)}
            currency_symbol={currency_symbol}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceDateSection;

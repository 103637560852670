import React, { useEffect, useState } from 'react';
import logo from "../../../images/logo.png";
import img from "../../../images/Celebrating-11years-24.png";


const SuccessModal = ({ isOpen, fbPixelTriggered, onClose, onfbPixelTriggered }: { isOpen: boolean; fbPixelTriggered: boolean; onClose: () => void; onfbPixelTriggered: () => void;  }) => {
    
  
    useEffect(() => {
      if (isOpen && !fbPixelTriggered) {
        // Assuming fbq is available globally
        if (window.fbq) {
          window.fbq('track', 'Lead');
          onfbPixelTriggered();
        }
      
      }
    }, [isOpen, fbPixelTriggered]); 

  const onClick = () => {
    window.location.href = "https://adansitravels.com";
    onClose();
  }

  if (!isOpen) {
    return null; // Optional, depending on your use case
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-5xl mx-auto">
        <div className="flex justify-end">
          <button
            onClick={onClick}
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M6.225 4.811l-1.414 1.414L10.586 12l-5.775 5.775 1.414 1.414L12 13.414l5.775 5.775 1.414-1.414L13.414 12l5.775-5.775-1.414-1.414L12 10.586z" />
            </svg>
          </button>
        </div>
        <div className="text-center">
          <img src={logo} alt="Thank you" className="mx-auto mb-4 w-24 h-20" />
          <div>
            <div className="container mx-auto">
              <div className="flex flex-col md:flex-row justify-center items-start">
                <div className="flex-1 flex flex-col items-stretch text-lg pt-13 font-medium mb-4 self-start mt-20 text-left space-y-4 md:space-y-0 md:space-x-4">
                  Thank You for Choosing Us to Book Your Trip! Your Trust Means
                  the World to Us. Get Ready for an Unforgettable Journey.
                  <div className="" style={{ margin: "50px", marginLeft: "", textAlign: "center" }}>
                    <button
                      className="text-sm text-center m-auto bg-green-700 text-white p-2 px-5 rounded-lg shadow-lg hover:bg-green-700 hover:shadow-xl transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400"
                      onClick={onClick}
                    >
                      Back to Homepage
                    </button>
                  </div>
                </div>
                <div className="flex-1">
                  <img
                    decoding="async"
                    width="923"
                    height="1024"
                    src={img}
                    className="w-full max-w-[923px] h-auto"
                    alt=""
                    sizes="(max-width: 923px) 100vw, 923px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;

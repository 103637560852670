import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/AdwayBtn";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";


const MobileFooterSticky = () => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));

    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const url = useSelector((state) => state.globalConfig.bookingEngineUrl); // Access state
    // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const selected_tour = useSelector((state) => state.globalConfig.selected_tour); // Access state
  const router = useNavigate();

  const goback = () => {
    router(-1);
  };

  const goToCheckout = () => {
    const { destination, name } = selected_tour;
    const baseURL ="https://workstation.adansitravels.com/online_bookings/";
    const userId = null;
    const booking_url = userId ? `${baseURL}?t=${name}&d=${destination}&u=${userId}` : `${baseURL}?t=${name}&d=${destination}`;
    window.open(booking_url, '_blank'); 
};



  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
          <ButtonPrimary
            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
            className="bg-[#dcdcdc]"
            onClick={goback}
          >
            Back
          </ButtonPrimary>
          </span>
         
        </div>
        <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={goToCheckout}
            >
              Book Now
            </ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
